/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useRef, useEffect} from "react";
import { CustomButton } from "../custom-button/custom-buttom";
import { BiCalendarEvent } from "react-icons/bi";
import { KTSVG } from "../../../helpers";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tr from 'date-fns/locale/tr';

import { styles } from "../../../assets/style/styles";
import '../../../assets/style/styles';
import AWS from "aws-sdk";
import { start } from "repl";
import {Spinner} from "react-bootstrap";

const NewLocation = ({
    userData,
    setUserData,
    error,
    setError,
    DatePicker,
    setLoader,
    saveData,
    modalData,
    address,
    setPosition,
    setType,
    nameInputRef,
    handleNameInputChange,
}:any) => {

    const [name, setName] = useState();
    const [nameExist, setNameExist] = useState(false);
    const [fileExist, setFileExist] = useState(false);
    const [dateExist, setDateExist] = useState(false);
    const [termsOfUseExist, setTermsOfUseExist] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    // @ts-ignore
    const endpoint = new AWS.Endpoint(process.env.REACT_APP_AWS_URL);
    const s3 = new AWS.S3({
        endpoint: endpoint,
        accessKeyId: process.env.REACT_APP_AWS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    });
    const [startDate, setStartDate] = useState();
    const [file, setFile] = useState(null);
    const [textCount, setTextCount] = useState(0);
    const hiddenInput = useRef<HTMLInputElement>(null)
    const nameRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLTextAreaElement>(null);

    const allowedTypes = [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/webp'
    ]

    useEffect(() => {
        //@ts-ignore
        if(name?.length > 0){
            setNameExist(true);
        }
    }, [name])
    useEffect(() => {
        if(startDate){
            setDateExist(true);
        }
    }, [startDate])
    useEffect(() => {
        //@ts-ignore
        if(nameExist && fileExist && textCount > 0 && termsOfUseExist && dateExist){
            setButtonDisabled(false);
        }else{
            setButtonDisabled(true);
        }
    }, [nameExist, fileExist, textCount, termsOfUseExist, dateExist])

    const handleSave = async (e: any) => {
        const firstName = nameRef?.current?.value;
        const detail = descriptionRef?.current?.value;
        setLoader(true);
        saveData(firstName, detail);
        setTimeout(() => {
            setLoader(false);
        }, 3500)

    }
    const handleFileUpload = (e: any) => {
        if (!allowedTypes.includes(e.target.files[0].type)){
            setError('Lütfen sadece .jpeg, .jpg ve .png formatlarında dosya yükleyiniz.')
            return
        }
        if (error)
            setError(null)
        setLoading(true)
        let rnd = (Math.random() + 1).toString(36).substring(2)
        setFile(e.target.files[0]);
        const tmpFile = e.target.files[0]
        const key = `${process.env.REACT_APP_AWS_DIR}/${rnd}_${tmpFile.name}`
        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET,
            Body: tmpFile,
            Key: key,
            ContentType: tmpFile.type
        };
        // @ts-ignore
        s3.putObject(params,  (err, data) => {
            if (err) {
                console.log("Error:", err);
            } else {
                setFileExist(true);
                setUserData((prevState: any) => ({...prevState, path: [`${process.env.REACT_APP_AWS_PUBLIC_URL}/${key}`]}))
                setLoading(false)
            }
        });
    }
    const selectImage = () => {
        if(hiddenInput.current != null){
            hiddenInput.current.click();
        }
    }

    return (
        <div className='card shadow-none rounded-0 w-100' style={styles.helpDrawerStyle.cardContainer}>
            {/* begin::Header */}
            <div className='card-header' id='kt_help_header_new'>
                <h5 className='card-title fw-bold text-gray-600'>Hafıza Hatırası</h5>
                <div className='card-toolbar'>
                    <button
                        type='button'
                        className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                        id='kt_help_close'
                        onClick={() => setPosition(undefined)}
                    >
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2'/>
                    </button>
                </div>
            </div>
            {/* end::Header */}
            {}
            {/* begin::Body */}
            <div className='card-body' id='kt_help_body'>
                {/* begin::Content */}
                <div
                    id='kt_help_scroll'
                    className='hover-scroll-overlay-y'
                    data-kt-scroll='true'
                    data-kt-scroll-height='auto'
                    data-kt-scroll-wrappers='#kt_help_body'
                    data-kt-scroll-dependencies='#kt_help_header'
                    data-kt-scroll-offset='5px'
                >
                    <div style={{border: '1px solid #CDC4C2', borderRadius: 4}}>
                        <div className='fv-row' style={styles.helpDrawerStyle.locationContainer}>
                            {/* begin::Label */}
                            <label className='text-muted fw-bold fs-6 mb-2'>
                                <img src='/media/map/user_sm.png' alt=""/>
                                Hatıra Konumu
                            </label>
                            {/* end::Label */}

                            {/* begin::Input */}

                            {/* end::Input */}
                        </div>
                        {/* address */}
                        <a className='header-address-text mt-3 mb-7 text-align-center tag-text' style={{textAlign: 'center',  display: 'flex', justifyContent: 'center'}}>{address}</a>
                    </div>
                    {/* Name Field */}
                    <div className='fv-row mb-7 mt-10'>
                        <label className='required fw-bold fs-6 mb-2'>İsminiz</label>
                        {/* begin::Input */}
                        <input
                           
                            ref={nameRef}
                            placeholder='İsminizi girin'
                            type='text'
                            name='name'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                            autoComplete='off'
                            onChange={event => {
                                // setUserData({...userData, name: event.target.value})
                                //@ts-ignore
                                setName(event.target.value)
                            }}
                            // onChange={handleNameInputChange}
                            style={styles.helpDrawerStyle.charField}
                        />
                        {/* end::Input */}
                    </div>
                    {/* Date Field */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>Tarih</label>
                        {/* end::Label */}
                        <div className='col-12' style={{border: '1px solid #CDC4C2', borderRadius: '8px', display:'flex'}}>
                            <div className='col-11 ml-4'>
                                <DatePicker
                                    id='date-picker-input'
                                    wrape
                                    className='custom-date-picker'
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="   Fotoğrafı çektiğiniz tarih"
                                    locale="tr"
                                    todayButton="Bugün"
                                    placeholderTextStyle={{fontFamily: 'source-sans-pro-regular', color: 'red', }}
                                    selected={startDate}
                                    maxDate={new Date()}
                                    onChange={(date: any) => {
                                        setStartDate(date)
                                        let month = date.getMonth()
                                        if (month < 9)
                                            month = `0${month + 1}`
                                        else
                                            month += 1
                                        let day = date.getDate()
                                        if (day < 10)
                                            day = `0${day}`
                                        setUserData({...userData, date: `${day}/${month}/${date.getFullYear()}`})
                                }}/>
                            </div>
                            <div className='col-1' style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <BiCalendarEvent  size={30} />
                            </div>


                        </div>
                        {/* begin::Input */}

                                        {}
                        {/* end::Input */}
                    </div>
                    {/* Memory Field */}
                    <div className='fv-row mb-3'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>Hatıranız</label>
                        {/* end::Label */}
                        {/* begin::Input */}
                        <textarea
                            ref={descriptionRef}
                            placeholder='Hatıranızı anlatın'
                            rows={4}
                            maxLength={350}
                            name='detail'
                            className='form-control form-control-solid mb-3 mb-lg-0 textInput'
                            autoComplete='off'
                            onChange={event => {
                                // setUserData({...userData, detail: event.target.value})
                                setTextCount(event.target.value?.length)
                            }}
                            style={styles.helpDrawerStyle.charField}
                        />
                        <div className='' style={styles.helpDrawerStyle.textCounter}>
                            <span>{textCount}/350</span>
                        </div>

                        {/* {textCount} */}
                        {/* end::Input */}
                    </div>
                    {/* Image Field */}
                    <div className='mb-8'>
                        <label className='required fw-bold fs-6 mb-2'>Fotoğrafınız</label>
                        <div className='col-12 row m-0' style={{...styles.helpDrawerStyle.charField, height:48}}>
                            <div className='col-8' style={{display:'flex', alignItems: 'center'}}>
                                {file ? file['name'] : <span style={{color: '#2D191E61', fontSize: 14, fontWeight: 400}}>Fotoğrafınızı yükleyin</span>}
                            </div>
                            <div id="image_selector" className='col-4 imageSelector' onClick={selectImage}>
                                {loading ? <Spinner animation="border"/> : null}
                                <span style={{fontSize: 14, fontWeight: 400, fontFamily: 'source-sans-pro-regular', color: '#503F40'}}>{loading ? 'Fotoğraf yükleniyor' : 'Fotoğraf seç'}</span>
                            </div>
                        </div>
                        <input id="input_hidden" type='file' onChange={handleFileUpload} ref={hiddenInput} accept=".jpeg,.jpg,.png,.webp"></input>
                        {/* <Form.Group controlId="formFile" className="mb-3">
                            <label className='required fw-bold fs-6 mb-2'>Fotoğrafınız</label>
                            <Form.Control title='adfaslşasdfgişl' type="file" accept=".png,.jpg,.jpeg" onChange={handleFileChange}/>
                        </Form.Group> */}
                    </div>
                    {/* Privacy Policy */}
                    <div className="mb-10">
                        {/*<GoogleReCaptcha onVerify={handleVerify}/>*/}
                        <div id="google-re-captcha" className="form-check form-check-custom form-check-solid">
                            <input className="bg-primary" type="checkbox" value="" style={{backgroundColor: 'white'}}
                                id="flexCheckDefault"
                                onChange={event => {
                                    if(event.target.checked){
                                        setTermsOfUseExist(true);
                                    }
                                    setUserData({...userData, accept: event.target.checked});
                                }}
                            />
                            <div className="form-check-label d-flex mt-4" style={{}}>
                                <p style={{color: '#2D191EB8', fontWeight: 400, fontFamily: 'source-sans-pro-regular'}}>
                                    <u
                                        id="terms-of-use-tag"
                                        style={{color: '#2D191EB8', fontWeight: 600, fontFamily: 'source-sans-pro-bold'}}
                                        onClick={() => {

                                            setType('termsOfUse');
                                        }}
                                    >
                                        Kullanım Koşullarını&nbsp;
                                    </u>
                                    okudum ve kabul ediyorum
                                </p>
                            </div>
                        </div>
                    </div>
                    <CustomButton
                        buttonTitle={'Gönder'}
                        onClick={handleSave}
                        buttonDisabled={buttonDisabled}
                    />
                    {/* Wannings */}
                    <div className="mb-10">
                        {error !== '' ? (
                            <span className="alert alert-danger">
                                {error}
                            </span>
                        ) : null}
                    </div>
                </div>
            {/* end::Content */}
            </div>
        {/* end::Body */}
        </div>
    )
}

export {NewLocation}
